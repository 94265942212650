import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { StyledLink } from 'app/components/Button'
import {
  realmInfoRequest,
  realmOptionsRequest,
  getEnvOption,
  clearErrorMessage,
} from 'app/pages/login/actions'
import { crendentialsViewPath } from 'app/pages/login/constants'
import {
  getRealmInfo,
  getAvailableEnvLoginOptions,
  getAvailableRealmLoginOptions,
} from 'app/pages/login/selectors'
import { ButtonsHolder } from 'app/components/Panel'
import * as FormElements from 'app/components/form/FormElements'
import {
  GoogleBtn,
  MSBtn,
  OPENIDBtn,
  OKTABtn,
} from 'app/components/LoginWithBtns'

const realmType = 'REALM'
const googleType = 'GOOGLE'

const LoginRealm = ({
  realmInfoRequest,
  realmOptionsRequest,
  realmInfo,
  getEnvOption,
  availableEnvOptions,
  availableRealmOptions,
  clearErrorMessage,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    realmInfoRequest()
    realmOptionsRequest()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getLoginOptions = useCallback(() => {
    const realmAddress = realmInfo.address
    if (realmAddress) getEnvOption(realmAddress)
  }, [realmInfo, getEnvOption])

  useEffect(() => {
    getLoginOptions()
  }, [realmInfo, getLoginOptions])

  const openCredentialsModal = () => {
    clearErrorMessage()
    navigate(crendentialsViewPath)
  }

  const createLoginButton = (loginOption) => {
    if (loginOption.type === 'GOOGLE') {
      return <GoogleBtn key={loginOption.type} type={loginOption.type} />
    }
    if (loginOption.type === 'GRAPH') {
      return <MSBtn key={loginOption.type} type={loginOption.type} />
    }


    if (loginOption.type === 'OPENID') {
      return (
        <OPENIDBtn
          key={loginOption.type}
          realmInfo={realmInfo}
          type={loginOption.type}
          tenantUuid={loginOption.uuid}
        />
      )
    }
    if (loginOption.type === 'OKTA') {
      return (
        <OKTABtn
          key={loginOption.type}
          realmInfo={realmInfo}
          type={loginOption.type}
          tenantUuid={loginOption.uuid}
        />
      )
    }
    if (loginOption.type === 'REALM')
      return (
        <StyledLink key={loginOption.uuid} onClick={openCredentialsModal}>
          Sign in with email
        </StyledLink>
      )
  }

  if (!availableEnvOptions.length && !availableRealmOptions.length)
    return (
      <FormElements.LoginRow>
        <p>Loading...</p>
      </FormElements.LoginRow>
    )
  let uniqueEnvOptions = {}
  availableEnvOptions.forEach((element) => {
    uniqueEnvOptions[element.type] = element
  })

  let uniqueOptionsByType = {}
  availableRealmOptions.forEach((element) => {
    uniqueOptionsByType[element.type] = element
  })

  const showRealmOption = (realmOption) => {
    return realmOption ? createLoginButton(realmOption) : null
  }
  const showGoogleOption = (googleOption) => {
    return googleOption ? createLoginButton(googleOption) : null
  }

  return (
    <ButtonsHolder>
      {showGoogleOption(uniqueOptionsByType[googleType])}

      {Object.values(uniqueOptionsByType).map((option) =>
        option.type !== googleType ? createLoginButton(option) : null
      )}
      {Object.values(uniqueEnvOptions).map((option) => {
        return option.type !== realmType ? createLoginButton(option) : null
      })}
      {showRealmOption(uniqueEnvOptions[realmType])}
    </ButtonsHolder>
  )
}

LoginRealm.propTypes = {
  realmInfoRequest: PropTypes.func,
  getEnvOption: PropTypes.func,
  realmInfo: PropTypes.object,
  availableEnvOptions: PropTypes.array,
  availableRealmOptions: PropTypes.array,
  realmOptionsRequest: PropTypes.func,
  clearErrorMessage: PropTypes.func,
}

const mapStateToProps = (state) => ({
  realmInfo: getRealmInfo(state),
  availableEnvOptions: getAvailableEnvLoginOptions(state),
  availableRealmOptions: getAvailableRealmLoginOptions(state),
})

export default connect(mapStateToProps, {
  realmInfoRequest,
  realmOptionsRequest,
  getEnvOption,
  clearErrorMessage,
})(LoginRealm)
