import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledLabel = styled.label`
  width: 10rem;
  padding: 1.2rem 1rem;
  padding-left: 0;
  align-self: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
`

const Label = (props) => {
  const { title, children, ...rest } = props

  if (title) {
    return <StyledLabel {...rest}>{children}</StyledLabel>
  }

  return <StyledLabel {...props} />
}

Label.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}

export default Label
