import React from 'react'
import styled from 'styled-components'

import { Header } from 'app/components/Panel.jsx'
import LoginPanel from 'app/components/LoginPanel.jsx'
import FlexSpacer from 'app/components/FlexSpacer.jsx'
import ConsentView from 'app/pages/login/ConsentView'
import { LoginLogo } from 'app/components/Panel.jsx'

export const StyledHeader = styled(Header)`
  font-weight: 500;
  margin: 4rem 0 1.2rem 0;
`
export const StyledPanel = styled(LoginPanel)`
  padding: 0 9rem !important;
   /* Media Query for Mobile Devices */
  @media (max-width: 480px) {
      padding:  0 4rem !important;
  }

  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 481px) and (max-width: 1024px) {
       padding: 0 12rem !important;
  }
`
const ConsentInfo = () => {
  return (
    <StyledPanel>
      <LoginLogo margin={'9rem auto 0 auto'}/>
      <StyledHeader>Consent is required to proceed </StyledHeader>
      <ConsentView />
      <FlexSpacer />
    </StyledPanel>
  )
}

export default ConsentInfo
