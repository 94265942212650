import styled from 'styled-components'

export const BackgroundHolder = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: ${(props) =>
    props.isPortal
      ? `url(${process.env.PUBLIC_URL}/flowscape_portal_background.jpg)`
      : `url(${process.env.PUBLIC_URL}/desk.jpg)`};
  background-size: cover;
  background-position: 60% 0;
  background-repeat: no-repeat;
  z-index: -1;
  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 0) and (max-width: 1024px) {
   width: 100%;
  }

`
