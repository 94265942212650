import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

export const StyledInput = styled.input`
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  font-family: inherit;
  width: 100%;
  height:5rem;
  border: 0.1rem solid ${(props) => props.theme.statusAway};
  box-sizing: border-box;
  letter-spacing: 0.03em;
  background-color: ${(props) => props.theme.panelBgLight};
  margin: 0;
  :focus {
    outline: none !important;
    border: 0.1rem solid #0e9571;
    background-color: ${(props) => props.theme.panelBgLight};
    box-shadow: 0 0 0.5rem #719ece;
  }
`

export const Wrapper = styled.label`
  min-height: 4rem;
  display: flex;
`

const Input = (props) => (
  <Wrapper type={props.type}>
    <StyledInput id={props.name} tabIndex='0' {...props.input} {...props} />
  </Wrapper>
)

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default Input
