import styled from 'styled-components'

export const Header = styled.div`
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 5.3rem 0;
  span {
  text-transform: none;
  }
`
export const LoginHeader = styled(Header)`
  @media (min-width: 0px) and (max-width: 767px) {
   margin-bottom: 3.6rem;
   font-size: 1.8rem;
  }
`
