import styled, { css } from 'styled-components'

const Row = styled.div`
  margin: 2rem 0;
  position: relative;
  display: flex;
  padding: 0 2rem;
  max-width: 64rem;
  align-items: center;

  ${(props) =>
    props.buttons &&
    css`
      margin-top: 5rem;
      flex-direction: row-reverse;

      button,
      a {
        margin: 0 0 0 1.2rem;
      }
    `};
`

export default Row
