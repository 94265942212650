import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { config } from 'app/api/public'

import * as FormElements from 'app/components/form/FormElements'
import { getAvailableREALMS } from 'app/pages/login/selectors'
import { getAvailableRealms } from 'app/pages/login/actions'
import SubmitButton from 'app/components/SubmitButton'

const LinkContainer = styled.div`
  max-height: 75%;
  overflow: auto;
  width: 90%;
  margin: 0 auto;
  &::-webkit-scrollbar {
    width: 1.2rem;
  }

  &::-webkit-scrollbar-track {
    background: #fffcfa;
  }

  &::-webkit-scrollbar-thumb {
    border: 0.35rem solid #fffcfa;
    background-color: #d8d8d8;
    border-radius: 0.7rem;
  }

  @media (min-height: 0px) and (max-height: 400px) {
   max-height: 25%;
  }
  @media (min-height: 401px) and (max-height: 599px) {
   max-height: 45%;
  }
  @media (min-height: 600px) and (max-height: 1024px) {
    max-height: 70%;
  }
`

const LinkButton = styled(SubmitButton) `{
  background-color: transparent;
  color:#084e75;
  width:100%;
  height:4rem;
  margin-bottom:2rem !important;
  border: 0.1rem solid #084e75;
  :hover {
  background-color: #edf7fc;
  }
}
`
const RealmsList = ({ getAvailableRealms, realms }) => {
  useEffect(() => {
    getAvailableRealms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectRealm = async (realm_uuid) => {
    const central_host = await config('central_hostname')
    const api_host =
      process.env.NODE_ENV === 'production'
        ? window.location.host
        : central_host
    window.location = `https://${api_host}/reception/realm/select/${realm_uuid}`
  }

  return (
    <LinkContainer>
      {realms.map((realm) => (
        <FormElements.LoginRow key={realm.uuid}>
          <LinkButton onClick={() => selectRealm(realm.uuid)} primary>
            {realm.name}
          </LinkButton>
        </FormElements.LoginRow>
      ))}
    </LinkContainer>
  )
}
RealmsList.propTypes = {
  realms: PropTypes.array,
  getAvailableRealms: PropTypes.func,
}
const mapStateToProps = (state) => ({
  realms: getAvailableREALMS(state),
})

export default connect(mapStateToProps, {
  getAvailableRealms,
})(RealmsList)
