import React, { useEffect } from 'react'

import { Header } from 'app/components/Panel.jsx'
import LoginPanel from 'app/components/LoginPanel.jsx'
import RealmsList from 'app/pages/login/RealmsList.jsx'
import { LoginLogo } from 'app/components/Panel.jsx'

const SelectRealm = () => {
  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoginPanel>
      <LoginLogo margin={'7.5rem auto 0 auto'}/>
      <Header margin={'3.8rem auto 2rem auto'}>Select Environment</Header>
      <RealmsList />
    </LoginPanel>
  )
}

export default SelectRealm
