import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as FormElements from 'app/components/form/FormElements'
import SubmitButton from 'app/components/SubmitButton'
import { passViewPath, changedPassViewPath } from 'app/pages/login/constants'
import {
  getRealmInfo,
  getErrorMsg,
  getEmailValue,
  getRequestIsValid,
} from 'app/pages/login/selectors'
import { triggerNewPassword, clearValidRequest } from 'app/pages/login/actions'
import { LoginLogo } from 'app/components/Panel.jsx'

const Title = styled.p`
  font-size: ${(props) => (props.subTitle ? '1.6rem' : '1.8rem')};
  font-weight: ${(props) => (props.subTitle ? '400' : '500')};
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: ${(props) =>
    props.subTitle ? '0 auto 3rem auto' : '0 auto 1rem auto'};
`

const RequestPassForm = ({
  realmInfo,
  triggerNewPassword,
  errorMessage,
  emailValue,
  requestIsValid,
  clearValidRequest,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (requestIsValid) {
      clearValidRequest()
      navigate(changedPassViewPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestIsValid])

  const openPassPath = () => {
    navigate(passViewPath)
  }

  const requestNewPassword = () => {
    const realmAddress = realmInfo.address
    triggerNewPassword(realmAddress, emailValue)
  }

  return (
    <>
      <FormElements.BackButton marginBottom={'8rem'} onClick={openPassPath} />
      <LoginLogo />
      
      <FormElements.LoginRow>
        <Title>Request new password</Title>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <Title subTitle>
          Need to reset your password? Click on the button and follow the
          instructions.
        </Title>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <SubmitButton onClick={requestNewPassword} primary>
          Set new password
        </SubmitButton>
      </FormElements.LoginRow>
    </>
  )
}
RequestPassForm.propTypes = {
  realmInfo: PropTypes.object,
  triggerNewPassword: PropTypes.func,
  errorMessage: PropTypes.string,
  emailValue: PropTypes.string,
  requestIsValid: PropTypes.bool,
  clearValidRequest: PropTypes.func,
}
const mapStateToProps = (state) => ({
  realmInfo: getRealmInfo(state),
  errorMessage: getErrorMsg(state),
  emailValue: getEmailValue(state),
  requestIsValid: getRequestIsValid(state),
})

export default connect(mapStateToProps, {
  triggerNewPassword,
  clearValidRequest,
})(RequestPassForm)
