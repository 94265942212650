import React, { Component } from 'react'

import { ResponsiveLoginLogo } from 'app/components/Panel.jsx'
import { ResponsiveLoginPanel, ResponsiveWhitePanel, CenteredContent } from 'app/components/LoginPanel.jsx'
import FlexSpacer from 'app/components/FlexSpacer.jsx'
import LoginInit from 'app/pages/login/LoginInit.jsx'

class Login extends Component {
  state = {
    showValidateCompanyModal: false,
  }
  componentDidMount() {
    window.localStorage.setItem('applicationBase', window.location)
  }

  render() {
    const { showValidateCompanyModal } = this.state
    return (
      <CenteredContent>
        <ResponsiveWhitePanel showSmall={!showValidateCompanyModal} />
        <ResponsiveLoginPanel showSmall={!showValidateCompanyModal}>
          <ResponsiveLoginLogo showSmall={!showValidateCompanyModal} />
          <LoginInit
            hideLoginTitle={(value) =>
              this.setState({
                showValidateCompanyModal: value,
              })
            }
          />
          <FlexSpacer />
        </ResponsiveLoginPanel >
      </CenteredContent>
    )
  }
}

export default Login
