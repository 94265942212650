import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as FormElements from 'app/components/form/FormElements'
import { passViewPath } from 'app/pages/login/constants'
import { LoginLogo } from 'app/components/Panel.jsx'

const Title = styled.p`
  font-size: ${(props) => (props.subTitle ? '1.6rem' : '1.8rem')};
  font-weight: ${(props) => (props.subTitle ? '400' : '500')};
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: ${(props) =>
    props.subTitle ? '0 auto 3rem auto' : '0 auto 1rem auto'};
`

const Icon = styled.img`
  width: 4rem;
  height: 4rem;
  text-align: center;
`
const IconHolder = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2.6rem;
`

const ConfirmPassChangedPage = () => {
  const check_icon_src = `${process.env.PUBLIC_URL}/checked.svg`
  const navigate = useNavigate()

  const openPassPath = () => {
    navigate(passViewPath)
  }

  return (
    <>
      <FormElements.BackButton marginBottom={'8rem'} onClick={openPassPath} />
      <LoginLogo />
      <FormElements.LoginRow>
        <IconHolder>
          <Icon src={check_icon_src} alt='checked_icon' />
        </IconHolder>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <Title subTitle>
          Password reset request was sent successfully! Please check your email
          to reset your password.
        </Title>
      </FormElements.LoginRow>
    </>
  )
}
ConfirmPassChangedPage.propTypes = {}
const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(ConfirmPassChangedPage)
