import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { setEnterClicked } from 'app/pages/login/actions'
import { LoginPanelHolder } from 'app/components/Panel.jsx'
import Loader from 'app/components/Loader.jsx'
import { getIsFetching } from 'app/pages/login/selectors'
import { getAppData } from 'utils/localStorage'
import { portalName } from 'app/pages/login/LoginInit'

const enterKeyCode = 13
const loadingMinDuration = 1000 //1s

const LoginPanel = ({ setEnterClicked, children, className, isFetching }) => {
  const [showLoading, setShowLoading] = useState(isFetching)

  const handleEnterClicked = (e) => {
    if (e.keyCode === enterKeyCode) {
      setEnterClicked()
    }
  }
  useEffect(() => {
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(isFetching)
      }, loadingMinDuration)
    } else {
      setShowLoading(isFetching)
    }
  }, [isFetching, showLoading])

  const isPortal = getAppData() === portalName
  const loaderBackground = isPortal
    ? `${process.env.PUBLIC_URL}/flowscape_portal_background.jpg`
    : `${process.env.PUBLIC_URL}/desk.jpg`

  return (
    <LoginPanelHolder className={className} onKeyUpCapture={handleEnterClicked} focus={true}>
      {showLoading && <Loader bgrUrl={loaderBackground} />}
      {children}
    </LoginPanelHolder>
  )
}

LoginPanel.propTypes = {
  children: PropTypes.array,
  setEnterClicked: PropTypes.func,
  className: PropTypes.string,
  isFetching: PropTypes.bool,
}
const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
})

export default connect(mapStateToProps, {
  setEnterClicked,
})(LoginPanel)

export const ResponsiveLoginPanel = styled(LoginPanel)`
${(props) => (props.showSmall ? ` 
 @media (min-width: 0px) and (max-width: 480px) {
  position: absolute;
  height: 38rem;
  width: 33rem;
  margin: 0 auto;
  border-radius: 3.6rem;
  background-color: transparent;
  left: 0px;
  right: 0px;
  padding:0;
  }
  
  @media (min-width: 481px) and (max-width: 1024px) {
  position: absolute;
  height: 39rem;
  width: 40rem;
  margin: 0 auto;
  border-radius: 3.6rem;
  background-color: transparent;
  left: 0px;
  right: 0px;
  padding:0;
  }`
    : '')};
`

export const ResponsiveWhitePanel = styled(LoginPanel)`
  background-color: transparent;

${(props) => (props.showSmall ?
    `@media (min-width: 0px) and (max-width: 480px) {
  position: absolute;
  height: 38rem;
  width: 33rem;
  margin: 0 auto;
  border-radius: 3.6rem;
  background-color: white;
  opacity:0.9;
  left: 0px;
  right: 0px;
  padding:0;
  }
  
  @media (min-width: 481px) and (max-width: 1024px)  {
  display:block;
  position: absolute;
  height: 39rem;
  width: 40rem;
  margin: 0 auto;
  border-radius: 3.6rem;
  background-color: white;
  opacity:0.9;
  left: 0px;
  right: 0px;
  padding:0;
  }`
    : '')};
`

export const CenteredContent = styled.div`
  display: flex;
  align-items:center;
  width:100%;
  height:100%;
  position: absolute;
  top:0;
`
