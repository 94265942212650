import styled, { css } from 'styled-components'

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelBgLight};
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadow};
  overflow: hidden;

  ${(props) =>
    props.isForm &&
    css`
      margin: 2rem;
      max-width: 64rem;
    `};
`
export const LoginPanelHolder = styled(Panel)`
  position: absolute;
  height: 100%;
  width: 40%;
  right: 0;
  margin: auto;
  border-radius: 0.6rem;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  background-color: white;
  /* Media Query for Mobile Devices */
  @media (max-width: 480px) {
      width:100%;
  }

  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 481px) and (max-width: 1024px) {
      width:100%;
  }
`
export const LoginLogo = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '24rem auto 4.5rem auto')};
  width: 28rem;
  height: 3rem;
  background-image: url('${process.env.PUBLIC_URL}/logo-orange.svg');
  background-size: cover;

  @media (min-width: 0px) and (max-width: 480px) {
    margin: ${(props) => (props.margin ? '5rem auto 2rem auto' : '24rem auto 4.5rem auto')};
  }
  @media (min-height: 0px) and (max-height: 400px) {
    margin: ${(props) => (props.margin ? '5rem auto 2rem auto' : '24rem auto 4.5rem auto')};
  }
`
export const ResponsiveLoginLogo = styled(LoginLogo)`
 ${(props) => (props.showSmall ?
    `@media (min-width: 0px) and (max-width: 480px) {
   margin: 7rem auto 2rem auto;
   width: 24rem;
  height: 2.6rem;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
   margin: 7rem auto 2rem auto;
   width: 24rem;
  height: 2.6rem;
  }`
    : '')};
`
export const LoginCaption = styled.span`
  font-size: 3rem;
  font-weight: bold;
  left: 0;
  right: 0;
  margin: 3rem auto;
`
export const Header = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  left: 0;
  right: 0;
  margin: ${(props) => (props.margin ? props.margin : '10rem auto 3rem auto')};

  @media (min-width: 0px) and (max-width: 480px) {
    margin: ${(props) => (props.margin ? '1.8rem auto 2rem auto' : '10rem auto 3rem auto')};
  }
  @media (min-height: 0px) and (max-height: 400px) {
    margin: ${(props) => (props.margin ? '1.8rem auto 2rem auto' : '10rem auto 3rem auto')};
  }

`
export const Divider = styled.div`
  width: 100%;
  margin: 0 !important;
  height: ${(props) => (props.height ? props.height : '0.1rem')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '0.1rem')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '0.1rem')};
`
export const ButtonsHolder = styled.div`
  overflow: auto;
  text-align: center;
`
export const buttonPngWidth = '21.5rem'
export const buttonPngHeight = '4.2rem'
export const buttonGoogleWidth = '17.5rem'
export const buttonGoogleHeight = '4rem'

export default Panel
