import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as FormElements from 'app/components/form/FormElements'
import Label from 'app/components/form/Label'
import Password from 'app/components/form/Password'
import SubmitButton from 'app/components/SubmitButton'
import { Divider } from 'app/components/Panel.jsx'
import {
  crendentialsViewPath,
  codeViewPath,
  newPassViewPath,
} from 'app/pages/login/constants'
import {
  getUserName,
  getRealmInfo,
  getErrorMsg,
  getEmailValue,
  getCredentialsAreValid,
} from 'app/pages/login/selectors'
import {
  setErrorMessage,
  clearCredentials,
  postCredentials,
  clearErrorMessage,
  triggerCode,
} from 'app/pages/login/actions'
import { StyledLink } from 'app/components/Button'
import ErrorMsg from 'app/components/ErrorMsg'
import { errorMessages } from 'utils/errorMessages'
import { LoginLogo } from 'app/components/Panel.jsx'

const Title = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto 1rem auto;
`

const LinkBtn = styled(StyledLink)`
  font-weight: 400;
  text-align: center;
  font-size: ${(props) => (props.smallerText ? '1.2rem' : '1.4rem')};
  margin: 1rem 0 0 0;
`

const PasswordForm = ({
  realmInfo,
  userName,
  errorMessage,
  setErrorMessage,
  emailValue,
  clearCredentials,
  postCredentials,
  areCredentialsValid,
  triggerCode,
  clearErrorMessage,
}) => {
  const passwordKey = 'password'
  const [formValues, setFormValues] = useState({ knownBy: '', password: '' })
  const [visibility, setVisibility] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (areCredentialsValid) {
      const realmAddress = realmInfo.address
      const successful_login_url = `${realmAddress}/concierge/session/redirect`
      window.location = successful_login_url
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areCredentialsValid])

  const openEmailPath = () => {
    clearErrorMessage()
    clearCredentials()
    navigate(crendentialsViewPath)
  }
  const openCodePath = () => {
    const realmAddress = realmInfo.address
    triggerCode(realmAddress, emailValue)
    clearErrorMessage()
    navigate(codeViewPath)
  }
  const opeNewPassPath = () => {
    clearErrorMessage()
    navigate(newPassViewPath)
  }

  const loginWithCredentials = () => {
    const realmAddress = realmInfo.address
    if (formValues.password) {
      postCredentials(realmAddress, formValues)
    } else {
      setErrorMessage({ message: errorMessages.enterPass.emptyPass })
    }
  }

  return (
    <>
      <FormElements.BackButton
        marginBottom={'4.5rem'}
        onClick={openEmailPath}
      />
      <LoginLogo />

      <FormElements.LoginRow>
        <Title>Welcome {userName}!</Title>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <Label htmlFor={passwordKey}>Password</Label>
        <Password
          name={passwordKey}
          required
          visible={visibility}
          value={formValues[passwordKey]}
          toggleVisibility={() => setVisibility(!visibility)}
          onChange={(event) =>
            setFormValues({
              password: event.target.value,
              knownBy: emailValue,
            })
          }
        />
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        {errorMessage && (
          <ErrorMsg>
            {errorMessage !== errorMessages.changeErrorMessage
              ? errorMessage
              : errorMessages.enterPass.wrongPassword}
          </ErrorMsg>
        )}
        {!errorMessage && <Divider height={'1.8rem'} />}
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <SubmitButton onClick={loginWithCredentials} primary>
          Sign in
        </SubmitButton>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <LinkBtn onClick={openCodePath}>
          Or Sign in with vertification code
        </LinkBtn>
      </FormElements.LoginRow>
      <FormElements.LoginRow>
        <LinkBtn smallerText onClick={opeNewPassPath}>
          Request new password
        </LinkBtn>
      </FormElements.LoginRow>
    </>
  )
}
PasswordForm.propTypes = {
  realmInfo: PropTypes.object,
  setErrorMessage: PropTypes.func,
  userName: PropTypes.string,
  errorMessage: PropTypes.string,
  emailValue: PropTypes.string,
  clearCredentials: PropTypes.func,
  postCredentials: PropTypes.func,
  areCredentialsValid: PropTypes.bool,
  clearErrorMessage: PropTypes.func,
  triggerCode: PropTypes.func,
}
const mapStateToProps = (state) => ({
  realmInfo: getRealmInfo(state),
  userName: getUserName(state),
  errorMessage: getErrorMsg(state),
  emailValue: getEmailValue(state),
  areCredentialsValid: getCredentialsAreValid(state),
})

export default connect(mapStateToProps, {
  clearCredentials,
  setErrorMessage,
  postCredentials,
  clearErrorMessage,
  triggerCode,
})(PasswordForm)
